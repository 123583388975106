import { Box, Grid, Stack, Typography } from "@mui/material";
import { useContext } from "react";
import { AiOutlineDashboard } from "react-icons/ai";
import { BiSupport } from "react-icons/bi";
import { BsPersonVideo } from "react-icons/bs";
import { FaAddressBook, FaMicrophone } from "react-icons/fa";
import { FaImage, FaQuora } from "react-icons/fa6";
import { HiMiniVideoCamera } from "react-icons/hi2";
import { CiSettings } from "react-icons/ci";
import { IoSettings } from "react-icons/io5";

import { MdVideoLibrary } from "react-icons/md";
import { RiChatPollFill } from "react-icons/ri";
import { SiPagespeedinsights, SiPlausibleanalytics, SiSimpleanalytics, SiGoogleanalytics } from "react-icons/si";
import { TbCategoryFilled, TbNotification } from "react-icons/tb";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import LogoWhite from "../assets/images/theTeacherAppLogo.svg";
import { ThemeContext } from "../context/ThemeContext";
import { FaLongArrowAltRight } from "react-icons/fa";
import Style from "../styles/drawer.module.scss";
import { AuthContext } from "../context/AuthContext";

export default function Drawer() {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { listoggle, setListToggle, sublistoggle, setSubListToggle } =
    useContext(ThemeContext);
  const auth = useContext(AuthContext);
  let navigations = [
    {
      route: {
        main_routes: "Home",
        active_route: true,
        link: "/home",
        is_visible: true,
        icon: {
          main_icon: <AiOutlineDashboard className={Style?.icons} />,
        },
        sub_routes: [

        ],
      },
    },
    {
      route: {
        main_routes: "Class Management",
        active_route: true,
        link: "/class-management",
        is_visible: true,
        icon: {
          main_icon: <TbCategoryFilled className={Style?.icons} />,
        },
        sub_routes: [],
      },
    },
    {
      route: {
        main_routes: "Subject Management",
        active_route: true,
        link: "/subject-management",
        is_visible: true,
        icon: {
          main_icon: <TbCategoryFilled className={Style?.icons} />,
        },
        sub_routes: [],
      },
    },
    {
      route: {
        main_routes: "Topics Management",
        active_route: true,
        link: "/topics-management",
        is_visible: true,
        icon: {
          main_icon: <TbCategoryFilled className={Style?.icons} />,
        },
        sub_routes: [],
      },
    },
    {
      route: {
        main_routes: "Difficulty Level Management",
        active_route: true,
        link: "/difficulty-level-management",
        is_visible: true,
        icon: {
          main_icon: <TbCategoryFilled className={Style?.icons} />,
        },
        sub_routes: [],
      },
    },
    {
      route: {
        main_routes: "Cohort Management",
        active_route: true,
        link: "/notification-cohort-management",
        is_visible: true,
        icon: {
          main_icon: <TbCategoryFilled className={Style?.icons} />,
        },
        sub_routes: [],
      },
    },
    {
      route: {
        main_routes: "User Management",
        active_route: true,
        link: "/user-management",
        is_visible: true,
        icon: {
          main_icon: <FaAddressBook className={Style?.icons} />,
        },
        sub_routes: [],
      },
    },
    {
      route: {
        main_routes: "Learning",
        active_route: false,
        link: "/courses",
        is_visible: true,
        icon: {
          // main_icon: <HiMiniVideoCamera className={Style?.icons} />,
        },
        sub_routes: [
          {
            route: {
              main_routes: "Courses",
              active_route: true,
              link: "/courses",
              is_visible: true,
              icon: {
                main_icon: <HiMiniVideoCamera className={Style?.icons} />,
              },
              sub_routes: [],
            },
          },
          {
            route: {
              main_routes: "Short Videos",
              active_route: true,
              link: "/short-videos",
              is_visible: true,
              icon: {
                main_icon: <MdVideoLibrary className={Style?.icons} />,
              },
              sub_routes: [],
            },
          },
          {
            route: {
              main_routes: "Podcasts",
              active_route: true,
              link: "/podcasts",
              is_visible: true,
              icon: {
                main_icon: <FaMicrophone className={Style?.icons} />,
              },
              sub_routes: [],
            },
          },
          {
            route: {
              main_routes: "Webinars",
              active_route: true,
              link: "/webinars",
              is_visible: true,
              icon: {
                main_icon: <BsPersonVideo className={Style?.icons} />,
              },
              sub_routes: [],
            },
          },
        ],
      },
    },
    {
      route: {
        main_routes: "Engagement",
        active_route: false,
        link: "/manage-pages",
        is_visible: true,
        icon: {
          // main_icon: <SiPagespeedinsights className={Style?.icons} />,
        },
        sub_routes: [
          {
            route: {
              main_routes: "Sunday Polls",
              active_route: true,
              link: "/sunday-polls",
              is_visible: true,
              icon: {
                main_icon: <RiChatPollFill className={Style?.icons} />,
              },
              sub_routes: [],
            },
          },
          {
            route: {
              main_routes: "Teacher Lounge",
              active_route: true,
              link: "/manage-post",
              is_visible: true,
              icon: {
                main_icon: <RiChatPollFill className={Style?.icons} />,
              },
              sub_routes: [],
            },
          },
          {
            route: {
              main_routes: "Quiz Management",
              active_route: true,
              link: "/quizzes",
              is_visible: true,
              icon: {
                main_icon: <RiChatPollFill className={Style?.icons} />,
              },
              sub_routes: [],
            },
          },
          {
            route: {
              main_routes: "Impact Stories",
              active_route: true,
              link: "/impact-stories-management",
              is_visible: true,
              icon: {
                main_icon: <RiChatPollFill className={Style?.icons} />,
              },
              sub_routes: [],
            },
          },
          {
            route: {
              main_routes: "Partners",
              active_route: true,
              link: "/partners-management",
              is_visible: true,
              icon: {
                main_icon: <FaMicrophone className={Style?.icons} />,
              },
              sub_routes: [],
            },
          },


        ],
      },
    },
    {
      route: {
        main_routes: "Teaching kit",
        active_route: false,
        link: "/manage-pages",
        is_visible: true,
        icon: {
          // main_icon: <SiPagespeedinsights className={Style?.icons} />,
        },
        sub_routes: [
          {
            route: {
              main_routes: "Teaching Videos",
              active_route: true,
              link: "/teaching-videos",
              is_visible: true,
              icon: {
                main_icon: <MdVideoLibrary className={Style?.icons} />,
              },
              sub_routes: [],
            },
          },
          {
            route: {
              main_routes: "School Processes",
              active_route: true,
              link: "/school-resource",
              is_visible: true,
              icon: {
                main_icon: <MdVideoLibrary className={Style?.icons} />,
              },
              sub_routes: [],
            },
          },
          {
            route: {
              main_routes: "PBL",
              active_route: true,
              link: "/pbl-management",
              is_visible: true,
              icon: {
                main_icon: <FaMicrophone className={Style?.icons} />,
              },
              sub_routes: [],
            },
          },
          {
            route: {
              main_routes: "Holiday Homework",
              active_route: true,
              link: "/holiday-homework-management",
              is_visible: true,
              icon: {
                main_icon: <FaMicrophone className={Style?.icons} />,
              },
              sub_routes: [],
            },
          },
          {
            route: {
              main_routes: "Entry Exit",
              active_route: true,
              link: "/entry-exit-management",
              is_visible: true,
              icon: {
                main_icon: <RiChatPollFill className={Style?.icons} />,
              },
              sub_routes: [],
            },
          },
          {
            route: {
              main_routes: "TLM",
              active_route: true,
              link: "/tlm-management",
              is_visible: true,
              icon: {
                main_icon: <FaMicrophone className={Style?.icons} />,
              },
              sub_routes: [],
            },
          },
          {
            route: {
              main_routes: "Question Bank",
              active_route: true,
              link: "/question-bank",
              is_visible: true,
              icon: {
                main_icon: <FaMicrophone className={Style?.icons} />,
              },
              sub_routes: [],
            },
          },
          {
            route: {
              main_routes: "Worksheet",
              active_route: true,
              link: "/worksheet-management",
              is_visible: true,
              icon: {
                main_icon: <RiChatPollFill className={Style?.icons} />,
              },
              sub_routes: [],
            },
          },
          {
            route: {
              main_routes: "Lesson Plan",
              active_route: true,
              link: "/lesson-plan-management",
              is_visible: true,
              icon: {
                main_icon: <RiChatPollFill className={Style?.icons} />,
              },
              sub_routes: [],
            },
          },
          {
            route: {
              main_routes: "No Bag Activity",
              active_route: true,
              link: "/no-bag-activity-management",
              is_visible: true,
              icon: {
                main_icon: <RiChatPollFill className={Style?.icons} />,
              },
              sub_routes: [],
            },
          },
          {
            route: {
              main_routes: "Check Your Understanding",
              active_route: true,
              link: "/check-your-understanding-management",
              is_visible: true,
              icon: {
                main_icon: <RiChatPollFill className={Style?.icons} />,
              },
              sub_routes: [],
            },
          },
        ],
      },
    },
    {
      route: {
        main_routes: "Channel",
        active_route: false,
        link: "/channel-codes",
        is_visible: true,
        icon: {
          // main_icon: <HiMiniVideoCamera className={Style?.icons} />,
        },
        sub_routes: [
          {
            route: {
              main_routes: "Channel Types",
              active_route: true,
              link: "/channel-type",
              is_visible: true,
              icon: {
                main_icon: <RiChatPollFill className={Style?.icons} />,
              },
              sub_routes: [],
            },
          },
          {
            route: {
              main_routes: "Channel Codes",
              active_route: true,
              link: "/channel-code",
              is_visible: true,
              icon: {
                main_icon: <RiChatPollFill className={Style?.icons} />,
              },
              sub_routes: [],
            },
          },
          {
            route: {
              main_routes: "Channel",
              active_route: true,
              link: "/channel-list",
              is_visible: true,
              icon: {
                main_icon: <RiChatPollFill className={Style?.icons} />,
              },
              sub_routes: [],
            },
          },
          {
            route: {
              main_routes: "Channel Banner",
              active_route: true,
              link: "/channel-banner",
              is_visible: true,
              icon: {
                main_icon: <RiChatPollFill className={Style?.icons} />,
              },
              sub_routes: [],
            },
          },
        ],
      },
    },
    {
      route: {
        main_routes: "Survey",
        active_route: true,
        link: "/teacher-servey",
        is_visible: true,
        icon: {
          main_icon: <RiChatPollFill className={Style?.icons} />,
        },
        sub_routes: [],
      },
    },
    {
      route: {
        main_routes: "CMS Pages",
        active_route: true,
        link: "/manage-pages",
        is_visible: true,
        icon: {
          main_icon: <SiPagespeedinsights className={Style?.icons} />,
        },
        sub_routes: [],
      },
    },

    {
      route: {
        main_routes: "Analytics",
        active_route: true,
        link: "/get-analytics",
        is_visible: true,
        icon: {
          main_icon: <SiGoogleanalytics className={Style?.icons} />,
        },
        sub_routes: [],
      },
    },
    {
      route: {
        main_routes: "FAQ Management",
        active_route: true,
        link: "/faq",
        is_visible: true,
        icon: {
          main_icon: <FaQuora className={Style?.icons} />,
        },
        sub_routes: [],
      },
    },
    {
      route: {
        main_routes: "Support Management",
        active_route: true,
        link: "/support-management",
        is_visible: true,
        icon: {
          main_icon: <BiSupport className={Style?.icons} />,
        },
        sub_routes: [],
      },
    },
    {
      route: {
        main_routes: "Subadmin Management",
        active_route: true,
        link: "/subadmin-management",
        is_visible: true,
        icon: {
          main_icon: <TbCategoryFilled className={Style?.icons} />,
        },
        sub_routes: [
        ],
      },
    },
    {
      route: {
        main_routes: "",
        active_route: false,
        link: "/subadmin-management",
        is_visible: true,
        icon: {
          // main_icon: <TbCategoryFilled className={Style?.icons} />,
        },
        sub_routes: [
          {
            route: {
              main_routes: "Assign Channel",
              active_route: true,
              link: "/channeladmin-management",
              is_visible: true,
              icon: {
                main_icon: <RiChatPollFill className={Style?.icons} />,
              },
              sub_routes: [],
            },
          },
        ],
      },
    },
    {
      route: {
        main_routes: "Category Management",
        active_route: true,
        link: "/category-management",
        is_visible: true,
        icon: {
          main_icon: <TbCategoryFilled className={Style?.icons} />,
        },
        sub_routes: [],
      },
    },

    {
      route: {
        main_routes: "Banners",
        active_route: true,
        link: "/banner",
        is_visible: true,
        icon: {
          main_icon: <FaImage className={Style?.icons} />,
        },
        sub_routes: [],
      },
    },

    {
      route: {
        main_routes: "Manage Notifications",
        active_route: true,
        link: "/notifications",
        is_visible: true,
        icon: {
          main_icon: <TbNotification className={Style?.icons} />,
        },
        sub_routes: [],
      },
    },
    {
      route: {
        main_routes: "Settings",
        active_route: true,
        link: "/settings",
        is_visible: true,
        icon: {
          main_icon: <IoSettings className={Style?.icons} />,
        },
        sub_routes: [],
      },
    },


  ];

  const handleToggleMainMenu = (elem) => {
    setListToggle({
      ...listoggle,
      [elem?.route?.main_routes]: !listoggle[elem?.route?.main_routes],
    });
    if (elem?.route?.active_route) {
      navigate(elem?.route?.link);
    }
  };

  const handleToggleSubMenu = (elem, idx, innerIdx) => {
    if (sublistoggle[elem?.title] === false) {
      setSubListToggle({ ...sublistoggle, [elem?.title]: true });
    } else {
      setSubListToggle({ ...sublistoggle, [elem?.title]: false });
    }
  };
  return (
    <>
      <Box className={Style.open}>
        <Box className={Style?.logo} onClick={() => navigate("/home")}>
          <img src={LogoWhite} alt="logo" />
        </Box>
        {navigations.map((elem, idx) => {
          if (auth?.userData?.is_subadmin && (elem?.route?.main_routes === "Channel" || elem?.route?.main_routes === "Home" || elem?.route?.main_routes === "Analytics" || elem?.route?.main_routes === "Survey")) {
            return (
              <Box key={idx}>
                <Stack
                  className={
                    elem?.route?.active_route ?
                      pathname.includes(elem?.route?.link)
                        ? Style.navigation_selected
                        : Style.navigation_noselected
                      : Style.navigation_noselected}
                >
                  <Grid
                    onClick={() => handleToggleMainMenu(elem)}
                    container
                    className={Style.link_section}
                  >
                    <Grid item lg={2} md={2} sm={2}>
                      <Box ml={2}>{elem?.route?.icon?.main_icon}</Box>
                    </Grid>

                    <Grid item lg={8} md={8} sm={8}>

                      <Typography className={Style.title} style={{ display: "flex", }}>
                        {elem?.route?.main_routes}
                        {/* {elem?.route?.sub_routes.length ?
                                     <Box >{<FaLongArrowAltRight className={Style?.icons}/>} {elem?.route?.main_routes}</Box>
                                    :elem?.route?.main_routes} */}
                      </Typography>
                      {elem?.route?.sub_routes.length ? (
                        elem.route.sub_routes.map((elem, idx) => {
                          if (auth?.userData?.is_subadmin && (elem?.route?.main_routes === "Channel" || elem?.route?.main_routes === "Channel Banner")) {
                            return (
                              <>
                                <Stack
                                  className={
                                    pathname.includes(elem?.route?.link)
                                      ? Style.navigation_selected
                                      : Style.navigation_noselected
                                  }
                                >
                                  <Grid
                                    onClick={() => handleToggleMainMenu(elem)}
                                    container
                                    className={Style.link_section}
                                  >
                                    <Grid item lg={2} md={2} sm={2}>
                                      <Box >
                                        {elem?.route?.icon?.main_icon}
                                      </Box>
                                    </Grid>

                                    <Grid item lg={8} md={8} sm={8}>
                                      <Typography className={Style.title}>
                                        {elem?.route?.main_routes}

                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </Stack>
                              </>
                            );
                          } else if (!auth?.userData?.is_subadmin) {
                            return (
                              <>
                                <Stack
                                  className={
                                    pathname.includes(elem?.route?.link)
                                      ? Style.navigation_selected
                                      : Style.navigation_noselected
                                  }
                                >
                                  <Grid
                                    onClick={() => handleToggleMainMenu(elem)}
                                    container
                                    className={Style.link_section}
                                  >
                                    <Grid item lg={2} md={2} sm={2}>
                                      <Box >
                                        {elem?.route?.icon?.main_icon}
                                      </Box>
                                    </Grid>

                                    <Grid item lg={8} md={8} sm={8}>
                                      <Typography className={Style.title}>
                                        {elem?.route?.main_routes}

                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </Stack>
                              </>
                            );
                          } else {
                            return null
                          }

                        })
                      ) : (
                        null
                      )}

                    </Grid>
                  </Grid>
                </Stack>
              </Box>
            );
          } else if (auth?.userData?.is_superuser) {
            return (
              <Box key={idx}>
                <Stack
                  className={
                    elem?.route?.active_route ?
                      pathname.includes(elem?.route?.link)
                        ? Style.navigation_selected
                        : Style.navigation_noselected
                      : Style.navigation_noselected}
                >
                  <Grid
                    onClick={() => handleToggleMainMenu(elem)}
                    container
                    className={Style.link_section}
                  >
                    <Grid item lg={2} md={2} sm={2}>
                      <Box ml={2}>{elem?.route?.icon?.main_icon}</Box>
                    </Grid>

                    <Grid item lg={8} md={8} sm={8}>

                      <Typography className={Style.title} style={{ display: "flex", }}>
                        {elem?.route?.main_routes}
                        {/* {elem?.route?.sub_routes.length ?
                                     <Box >{<FaLongArrowAltRight className={Style?.icons}/>} {elem?.route?.main_routes}</Box>
                                    :elem?.route?.main_routes} */}
                      </Typography>
                      {elem?.route?.sub_routes.length ? (
                        elem.route.sub_routes.map((elem, idx) => {
                          return (
                            <>
                              <Stack
                                className={
                                  pathname.includes(elem?.route?.link)
                                    ? Style.navigation_selected
                                    : Style.navigation_noselected
                                }
                              >
                                <Grid
                                  onClick={() => handleToggleMainMenu(elem)}
                                  container
                                  className={Style.link_section}
                                >
                                  <Grid item lg={2} md={2} sm={2}>
                                    <Box >
                                      {elem?.route?.icon?.main_icon}
                                    </Box>
                                  </Grid>

                                  <Grid item lg={8} md={8} sm={8}>
                                    <Typography className={Style.title}>
                                      {elem?.route?.main_routes}

                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Stack>
                            </>
                          );
                        })
                      ) : (
                        null
                      )}

                    </Grid>
                  </Grid>
                </Stack>
              </Box>
            );
          } else {
            return null
          }
        })}
      </Box>
    </>
  );
}
